@import-normalize;
@import './theme.css';

* {
  box-sizing: border-box;
}

.u-font-weight--normal {
  font-weight: normal;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: var(--bg-color);
  color: var(--text-color);
  font-size: 3.666vh;
}

.App,
.App input, 
.App button {
  font-family: 'Poppins', 'Lato', 'Roboto', sans-serif;
}

.App a {
  color: var(--link-color);
  letter-spacing: .05em;
}

@media (min-width: 30em) {
  .App {
    width: auto;
    height: 90vh;
    justify-content: space-between;
    aspect-ratio: 1 / 1.75;

    margin: 0 auto;
    border-radius: var(--border-radius);
  }
}

.UnitConverter,
.UnitConverter__Display,
.UnitConverter__Numpad {
  display: grid;
  gap: .25em;
}

.UnitConverter {
  padding: .5em;
  padding-bottom: 0;
  grid-template-rows: 2fr 4fr;
}

@media (min-width: 30em) {
  .UnitConverter {
    padding: 1em;
    padding-bottom: 0;
  }
}

.UnitConverter__Input {
  min-width: 0; /* Required so increasing font-size doesn't increase input width */
  height: calc(100% - 0.15em);
  align-self: center;
  border-radius: var(--border-radius);
  font-size: 1.75em;
  font-weight: 900;
  text-align: right;
  padding: 0 .25em;
  border: none;
  background-color: var(--input-color);
  color: var(--input-text-color);
  box-shadow: inset 0 .15em 0 var(--btn-edge-color);
  transition: box-shadow .1s linear;
}

.UnitConverter__Input:focus {
  box-shadow: inset 0 0 0 var(--btn-edge-color);
  outline: .5vh solid var(--accent-color-2);
}

.UnitConverter__Input[readonly] {
  background-color: var(--output-color);
  color: var(--output-text-color);
  box-shadow: none;
}

.UnitConverter__Display {
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr 1fr;
  grid-template-areas: 
    "input input input inType"
    "output output output outType";
}

[name="inType"],
[name="outType"] {
  background-color: var(--accent-color-1);
}

[name="unitTypeSwap"] {
  display: grid;
  place-content: center;
  background-color: var(--accent-color-2);
}

[name="unitTypeSwap"] > svg {
  display: grid;
  place-content: center;
  transform: scale(1.75);
}

[name="inValue"] { grid-area: input; }
[name="inType"] { grid-area: inType; }
[name="outValue"] { grid-area: output; }
[name="outType"] { grid-area: outType; }

.UnitConverter__Numpad {
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-template-areas: 
    "seven eight nine typeSwap"
    "four five six clear"
    "one two three clear"
    "zero zero dot backspace";
}

[name="0"] { grid-area: zero; }
[name="1"] { grid-area: one; }
[name="2"] { grid-area: two; }
[name="3"] { grid-area: three; }
[name="4"] { grid-area: four; }
[name="5"] { grid-area: five; }
[name="6"] { grid-area: six; }
[name="7"] { grid-area: seven; }
[name="8"] { grid-area: eight; }
[name="9"] { grid-area: nine; }
[name="period"] { grid-area: dot; }
[name="backspace"] { grid-area: backspace; }
[name="clear"] { grid-area: clear; }
[name="unitTypeSwap"] { grid-area: typeSwap; }