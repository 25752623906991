/* #UnitSelectorButton */

.UnitSelectorButton,
.UnitSelectorButton__Dialog {
  background-color: var(--btn-color);
}

.UnitSelectorButton {
  position: relative;
  transition: all 0.15s ease-out;
}

.UnitSelectorButton:focus,
.UnitSelectorButton:focus-within,
.UnitSelectorButton--open {
  z-index: 1;
}

.UnitSelectorButton--open {
  box-shadow: none;
}

/* #Dialog */

.UnitSelectorButton__Dialog {
  position: absolute;
  transform-origin: top right;
  right: -2px;
  left: -50%;

  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius);
  border-top-right-radius: 0;
  border: 2px solid var(--btn-edge-color);
  border-top: none;
  
  transition: all .15s ease-out, opacity .15s linear, transform .2s ease-out;

  /* #Dialog Closed */
  pointer-events: none;
  top: 0;
  transform: scaleY(0.9);
  opacity: 0;
}

.UnitSelectorButton--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

/* #Dialog Open */

.UnitSelectorButton--open .UnitSelectorButton__Dialog {
  pointer-events: unset;
  top: 100%;
  transform: scaleY(1);
  opacity: 1;
}

.UnitSelectorButton--open,
.UnitSelectorButton--open .UnitSelectorButton__Dialog {
  background-color: var(--input-color);
  color: var(--input-text-color);
}

/* #Option */

.UnitSelectorButton__Dialog__Option {
  position: relative;
  display: flex;
  padding: .5em 1em;
  font-size: .5em;
  border-radius: var(--border-radius);

  transition: all .15s ease-out;
}

/* #Option Focused */

.UnitSelectorButton__Dialog__Option:focus-within {
  outline: .25em solid var(--accent-color-2);
}

.UnitSelectorButton__Dialog__Option:focus-within,
.UnitSelectorButton__Dialog__Option:hover {
  transform: scale(1.1);
  background-color: white;
  box-shadow: 0 0 2em hsla(200deg, 15%, 15%, .5);
}

.UnitSelectorButton__Dialog__Option > input {
  /* Hide, but keep accessible */
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.UnitSelectorButton__Dialog__Option > label {
  padding: 0.1em 0.5em;
  border-radius: 1.5vh;
  line-height: 1.5;
}

/* #Option Selected */

.UnitSelectorButton__Dialog__Option--selected > label {
  background-color: var(--accent-color-1);
  color: white;
}
