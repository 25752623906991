.Button {
  background-color: var(--btn-color);
  color: var(--text-color);
  border: none;
  border-radius: var(--border-radius);
  font-size: 1em;
  line-height: 1;
  font-weight: 900;
  transition: box-shadow .1s linear,
              transform .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  border: 2px solid var(--btn-edge-color);
  box-shadow: inset 0 -.15em 0 var(--btn-edge-color);
}

.Button:hover,
.Button:focus,
.Button:active {
  transform: translateY(.1em);
  box-shadow: inset 0 -.05em 0 var(--btn-edge-color);
}

.Button:focus {
  outline: .5vh solid var(--outline-color);
}